<template>
<div>


  <div class="container-fluid wrapper-medium mt-3">

    <div class="row mb-3">
      <div class="col-12 col-md-6 mb-3">
        <div class="sticky-top">
          <b-link class="btn btn-link btn-sm pl-0" to="/organisations-management"><span class="icon icon-left-open mr-2 pl-0"></span>Organisations management</b-link>
          <h2 class="anim-load1 mt-2 mb-3">Create new organisation</h2>
          <ol class="list-standard">
            <li>Enter the organisation details and set the initial number of credits.</li>
            <li>Organisation Admin will receive an email which will allow them to create an account, and login to TAYC-R.</li>
            <li>Organisation Admin will then be able to add more members within the organisation.</li>
          </ol>
        </div>
      </div>
      <div class="col-12 col-md-6">
        <div class="card mb-3">
          <div class="card-body">
            <ValidationObserver ref="form">
              <b-form @submit.prevent="submit" class="mb-3">

                <h4>Organisation details</h4>
                <div class="form-group" ref="organisationName">
                  <label for="organisationNameInput">Organisation name</label>
                  <ValidationProvider v-slot="{classes, errors}" rules="required" name="organisationName">
                    <input v-model="form.name" type="text" placeholder="" name="organisationNameInput" class="form-control" v-bind:class="classes">
                    <div class="invalid-feedback">{{ errors[0] }}</div>
                  </ValidationProvider>
                </div>

                <div class="form-group" ref="addressLine1">
                  <label for="addressLine1Input">Address line 1</label>
                  <input v-model="form.addressLine1" type="text" placeholder="" name="addressLine1Input" class="form-control">
                </div>

                <div class="form-group" ref="addressLine2">
                  <label for="addressLine2Input">Address line 2</label>
                  <input v-model="form.addressLine2" type="text" placeholder="" name="addressLine2Input" class="form-control">
                </div>

                <div class="form-group" ref="addressLine3">
                  <label for="addressLine3Input">Address line 3</label>
                  <input v-model="form.addressLine3" type="text" placeholder="" name="addressLine3Input" class="form-control">
                </div>

                <div class="form-group" ref="phoneNo">
                  <label for="phoneNoInput">Contact phone number</label>
                  <input v-model="form.phoneNo" type="tel" placeholder="" name="phoneNoInput" class="form-control">
                </div>

                <div class="form-group d-flex align-items-center justify-content-between" ref="credits">
                  <label for="creditsInput">Initial credits</label>
                  <ValidationProvider v-slot="{classes, errors}" rules="required|integer|max_value:999999" name="Number of credits">
                    <input v-model="form.credits" type="text" placeholder="" name="creditsInput" class="form-control" v-bind:class="classes">
                    <div class="invalid-feedback">{{ errors[0] }}</div>
                  </ValidationProvider>
                </div>

                <hr>

                <h4>Organisation Admin</h4>
                <div class="form-group" ref="title">
                  <label for="titleInput">Title</label>
                  <ValidationProvider name="titleInput" rules='required' v-slot="{errors}">
                    <v-select
                      :clearable="false"
                      :options="titleOptions"
                      label="text"
                      v-model="form.title"
                      :reduce="item => item.value"
                      placeholder="Select"
                      class="form-control"
                      :class="{'is-invalid': !!errors.length}"
                    ></v-select>
                    <div class="invalid-feedback">{{ errors[0] }}</div>
                  </ValidationProvider>
                </div>

                <div class="form-group" ref="firstName">
                  <label for="firstNameInput">First name</label>
                  <ValidationProvider v-slot="{classes, errors}" rules="required" name="firstName">
                    <input v-model="form.firstName" type="text" placeholder="" name="firstNameInput" class="form-control" v-bind:class="classes">
                    <div class="invalid-feedback">{{ errors[0] }}</div>
                  </ValidationProvider>
                </div>

                <div class="form-group" ref="lastName">
                  <label for="lastNameInput">Last name</label>
                  <ValidationProvider v-slot="{classes, errors}" rules="required" name="lastName">
                    <input v-model="form.lastName" type="text" placeholder="" name="lastNameInput" class="form-control" v-bind:class="classes">
                    <div class="invalid-feedback">{{ errors[0] }}</div>
                  </ValidationProvider>
                </div>

                <div class="form-group" ref="email">
                  <label for="emailInput">Email address</label>
                  <ValidationProvider v-slot="{classes, errors}" rules="required|email" name="Email address">
                    <input ref="emailInputRef" v-model="form.email" type="text" placeholder="" autocomplete="email" name="emailInput" class="form-control" v-bind:class="classes">
                    <div class="invalid-feedback">{{ errors[0] }}</div>
                  </ValidationProvider>
                </div>

                <b-alert variant="danger"
                  dismissible
                  :show="showDismissibleAlert"
                  @dismissed="showDismissibleAlert=false">
                  {{serverError}}
                </b-alert>
                <button type="submit" class="btn btn-warning btn-lg btn-block">Create and send invite</button>
              </b-form>
            </ValidationObserver> 
          </div> 
        </div>
      </div>
    </div>

  </div>

  <Footer />
</div>

</template>

<script>
export default {
  name: 'OrganisationsAdd',
  data () {
    return {
      form: {
        name: '',
        addressLine1: null,
        addressLine2: null,
        addressLine3: null,
        phoneNo: null,
        credits: 0,
        title: '',
        firstName: '',
        lastName: '',
        email: ''
      },
      serverError: '',
      showDismissibleAlert: false,
      titleOptions: this.$uiValues.titleOptions,
    }
  },

  async created() {
    this.$store.state.appSection = 'appUser'
    this.$store.state.loadingProgress = false
  },

  methods: {
    showError(txt) {
      this.serverError = txt
      this.showDismissibleAlert = true
    },
    submit() {
      this.$refs.form.validate().then(success => {
        if (!success) {
          setTimeout(() => {
            const errors = Object.entries(this.$refs.form.errors)
              .map(([key, value]) => ({ key, value }))
              .filter(error => error["value"].length);
            var el = this.$refs.form.refs[errors[0]["key"]].$el
            this.$scrollTo(el, 500, {offset: -100, cancelable: false})
          }, 100);
        } else {
          this.showDismissibleAlert = false
          this.$store.state.loadingProgress = true
          // Pick properties of the form
          // var picked = (({name, firstName}) => ({name, firstName}))(this.form)
          this.$http({
            method: 'post', 
            url: this.$api.OrganisationCreate,
            params: {
              ...this.form
            },
            headers: {'Authorization': 'bearer ' + this.$store.state.jwt } 
          })
            .then((response) => {
              console.log(response)
              if (response.data.success === true) {
                this.inviteTherapist(response.data.id)
              } else {
                this.showError(response.data.message)
                this.$store.state.loadingProgress = false
              }
            })
            .catch((error) => {
              console.error('Error', error)
              this.showError('Something went wrong. Please try again.')
              this.$store.state.loadingProgress = false
            })
        }
      });
    },

    inviteTherapist(organisationId) {
      this.$http({
        method: 'post', 
        url: this.$api.TherapistAdminInvite,
        params: {
          organisationId,
          admin: true,
          sendEmail: true,
          ...this.form
        },
        headers: {'Authorization': 'bearer ' + this.$store.state.jwt } 
      })
        .then((response) => {
          console.log(response)
          this.alertMsgShow('Organisation has been created. Invite sent.', true);
          this.$router.push('/organisations-management')
        })
        .catch((error) => {
          console.error('Error', error)
          this.showError('Something went wrong. Please try again.')
          this.$store.state.loadingProgress = false
        })
    }
  }
}
</script>
